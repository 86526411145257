import { type HttpResponse } from '@wix/yoshi-flow-editor';
import { getPostBySlug } from '@wix/ambassador-blog-v3-post/http';
import { type AppData } from '../../../../viewer.app';
import { completeFetchPost, fetchPost, preFetchPost } from '../../../common/actions/fetch-post';
import type { PlatformApi } from '../../../common/controller/platform-api';
import { resolvePostSlug } from '../../../common/services/slug';
import {
  type AppStore,
  type ControllerConfig,
  type FlowAPI,
  type NormalizedPost,
  type RouteResolverFn,
} from '../../../common/types';
import { setReadingSessionId } from '../../actions/reading-session-id-actions';
import { ROUTE_404 } from '../../constants/routes';
import { postPageRouterPostHandler } from './post-page-router-post-handler';
import { setPostIDInSlots } from './slot-handler';

interface CreatePostPageRouterParams {
  store: AppStore;
  platformApi: PlatformApi;
  appData: AppData;
  flowAPI: FlowAPI;
  slotAPIFactory: Record<string, any>;
  controllerConfig: ControllerConfig;
}

const POST_ID_KEY_IN_WARMUP_DATA = 'postId' as const;

export const createPostPageRouter =
  ({
    store,
    platformApi,
    appData,
    flowAPI,
    slotAPIFactory,
    controllerConfig,
  }: CreatePostPageRouterParams): RouteResolverFn =>
  async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const postSlug = resolvePostSlug(params);

    if (!postSlug) {
      throw new Error('post slug is not available');
    }

    const warmupData = platformApi.window.warmupData;
    const isSSR = flowAPI.environment.isSSR;
    const postId = !isSSR ? warmupData.get(POST_ID_KEY_IN_WARMUP_DATA) : undefined;

    if (postId) {
      setPostIDInSlots(postId, slotAPIFactory);
    } else if (isSSR || !postId) {
      flowAPI.httpClient
        .request(getPostBySlug({ slug: postSlug }))
        .then((response) => {
          const id = response.data.post?.id;
          if (isSSR) {
            warmupData.set(POST_ID_KEY_IN_WARMUP_DATA, id);
          } else {
            setPostIDInSlots(id, slotAPIFactory);
          }
        })
        .catch(() => {});
    }

    if (preFetch) {
      return store.dispatch(preFetchPost(postSlug));
    }

    const readingSessionId = `${parseInt(`${Math.random() * 10000000}`, 10)}-${Date.now()}`;
    store.dispatch(setReadingSessionId(readingSessionId));

    return (
      preFetchResult
        ? store.dispatch(completeFetchPost(postSlug, preFetchResult))
        : store.dispatch(fetchPost(postSlug))
    )
      .then((post: NormalizedPost) => {
        return postPageRouterPostHandler({
          post,
          store,
          appData,
          platformApi,
          flowAPI,
          redirect,
          controllerConfig,
        });
      })
      .catch((error: HttpResponse) => {
        if (error.status === 401) {
          return redirect(`/login?redirect=/${postSlug}`);
        }

        if (error.status === 404) {
          return redirect(ROUTE_404);
        }

        throw error;
      });
  };
